import PublisherTable from "../components/publisherTable/PublisherTable";

const Publishers = () => {
    return(
        <>
            <PublisherTable />
        </>
    )
}

export default Publishers;