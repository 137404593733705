import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ScrollAnimation from "react-animate-on-scroll";

// import utils
import { fetch } from '../../utils';

const PublisherTable = () => {
    const [key, setKey] = useState('today');
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch({ method: "GET", path: 'app-users/publisher/5a48b545' }).then(res => setData(res.data.docs))
    })

    return (
        <div className="col-12 col-xxl-6 container mt-5">
            <ScrollAnimation animateIn="fadeInUp" delay={500} animateOnce={true} >
                <div className="card border-0 shadow-sm dashboard-activity-tab">
                    <div className="card-body p-4 d-flex flex-wrap">
                        <h5>Publishers</h5>

                        <Tabs
                            id="dashboard-activity-tab"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="border-0 mb-3 ms-auto"
                        >
                            <Tab eventKey="today" title="Today">
                                <div className="table-responsive border shadow-sm dashboard-table activity-table">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>appUserKey</th>
                                                <th>publisherUserId</th>
                                                <th>runTime</th>
                                                <th>balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((elem, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <Link className="btn btn-minimal text-dark hover-primary" to="#">
                                                            {elem.userKey}
                                                        </Link>
                                                    </th>
                                                    <td>
                                                        <span className="d-inline-block fw-bold fz-14">{elem.publisherUserId}</span>
                                                    </td>
                                                    <td>
                                                        {elem.liveTime}
                                                    </td>
                                                    <td>
                                                        {elem.currencyEarned - elem.currencySpent}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    )
}

export default PublisherTable;