import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL

const fetch = ({ method = 'GET', data = {}, path = '' }) => {
    const lowerMethod = method.toLowerCase() || "";
    axios.defaults.headers.common['apiToken'] = process.env.REACT_APP_PUBLISHER_API_KEY
    
    if (axios[lowerMethod]) {
        return axios[lowerMethod](`${BASE_API_URL}${path}`, data);
    } else {
        return null;
    }
}

export default fetch;