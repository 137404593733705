import { Link } from "react-router-dom";
import { useEffect, useState } from 'react'
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Divider from "../components/divider/Divider";
import axios from 'axios';
import { Spinner } from 'reactstrap'
// import TodaysDrop from "../components/todaysDrop/TodaysDrop";
// import TopSeller from "../components/topSeller/top-seller";
// import TopBuyer from "../components/topBuyer/TopBuyer";
import Process from "../components/process/Process";
// import LiveAuction from "../components/liveAuction/LiveAuction";
import PopularCollection from "../components/popularCollection/PopularCollection";
import HeroTwo from "../components/hero/HeroTwo";
// import CTA from "../components/cta/CTA";
// import AppDownload from "../components/appDownload/AppDownload";
// import DiscoverTwo from "../components/discover/DiscoverTwo";


export default function HomeTwo() {
    const [downloading, setDownloading] = useState(false);
    let publisherKey = '';
    const handleClick = () => {
        if (downloading) {
            return
          }
      
        setDownloading(true)
        axios.get(`${process.env.REACT_APP_BASE_API_URL}pa/app-users/${process.env.REACT_APP_APP_USER_ID}`)
                .then(res => {
                    publisherKey = res.data.publisherKey;
                    axios.get(`${process.env.REACT_APP_BASE_API_URL}product/download-app/${publisherKey}`, {
                        responseType: 'blob', // This is important
                        timeout: 1800000 /** 5 mins */
                      })
                .then(response => {
                    //const href = URL.createObjectURL(response.data)
                    const blob = new Blob([response.data], { type: response.data.type }); // Create a Blob if not already one
                    const href = URL.createObjectURL(blob); 
                    const link = document.createElement('a')
                    link.href = href
                    link.setAttribute('download', 'installer.msi')
                    link.setAttribute('target', '_blank')
                    document.body.appendChild(link)
                    link.click()
            
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
            
                    setDownloading(false)
                }).finally(() => {
                    setDownloading(false)
                  })
                .catch(error => {
                    console.error('There was an error!', error);
                    setDownloading(false);
                });
                })
    }
    return (
        <>
            <Header />

            <HeroTwo
                heading="Cross Compatible NFTs For Mixed Reality"
                subHeading="Fully Authenticated NFTs That Are Portable To Most Virtual and Mixed World Applications"
                buttonInfo={[
                    {
                        btnColor: "primary",
                        btnText: "View All Collections",
                        btnURL: "/explore2",
                        btnIcon: "bi-grid-3x3-gap"
                    }
                ]}
            />

            <Divider />

            {/* <LiveAuction 
                backgroundColor="" 
                spinnerColor="danger" 
                title="Live Bid"
                buttonText="View All Bids" 
                buttonURL="/live-bidding" 
                buttonColor="primary"
            /> */}

            <Process />

            <Divider />

            <div className="container">
                <div className="row justify-content-center">
                    <a className={`btn mt-4 btn-primary rounded-pill w-100`} onClick={() => handleClick()} disabled={downloading}>
                        
                        {downloading ? <span> <Spinner size="sm" /> Downloading...</span>: <span><i className={`me-2 bi bi-grid-3x3-gap`}/>Download</span>}
                    </a>
                    
                    
                </div>
            </div>

            <Divider />

            <PopularCollection
                title="Popular items in last"
            />

            {/* <Divider /> */}

            {/* <DiscoverTwo
                title="Discover Items"
            /> */}

            {/* <Divider /> */}

            {/* Todays Drop, Top Seller, Top Buyer */}


            {/* <Divider /> */}

            {/* <CTA
                backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc 
                text="Resources for getting started with Funto."
                buttonText="Get Started"
                buttonColor="warning"
                buttonURL="/help-center"
                buttonIcon=""
            /> */}

            {/* <Divider /> */}

            {/* <AppDownload
                heading="Stay connected <br /> to all your devices."
                subHeading="Download our mobile apps today."
                qrCodeImage="img/core-img/qr-code.svg"
                appDownloadButtons={[
                    {
                        id: 1,
                        icon: "img/core-img/google-play.png",
                        path: "#"
                    },
                    {
                        id: 2,
                        icon: "img/core-img/app-store.png",
                        path: "#"
                    }
                ]}
                rightSideImage="img/illustrator/2.png"
            /> */}

            <Divider />

            <Footer />
        </>
    )
}