import {useState, useEffect, createContext, useContext} from 'react'

const balanceContext = createContext({
    balance: 0,
    setBalance: () => {}
});

const BalanceContextProvider = ({children}) => {
    const [balance, setBalance] = useState(0);

    const value = {
        balance, setBalance
    };

    return <balanceContext.Provider value={value}>
        {children}
    </balanceContext.Provider>
};

const useBalanceContext = () => useContext(balanceContext);

export {
    BalanceContextProvider,
    useBalanceContext,
}